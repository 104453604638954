<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司名称"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.bizCreditNo"
              placeholder="统一编码"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.legalPerson"
              placeholder="法定代表人"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="company" slot-scope="text, record">
        <div><small>{{ text }}</small></div>
        <div><small>{{ record.bizCreditNo }}</small></div>
      </template>
      <template slot="bizCreditImg" slot-scope="text, record">
        <div v-if="record.bizCreditImg" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.bizCreditImg || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.bizCreditImg || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无</div>
      </template>
      <template slot="amount" slot-scope="text, record">
        <div style="color: red" v-if="text.amount>0">￥ {{ text.amount || "" }}</div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" @click="modify(record)" size="small">修改</a-button>
        <a-button type="danger" @click="deleteOne(record)" style="margin-left:10px;" size="small">删除</a-button>
      </template>
    </a-table>
    <modify-company-modal ref="modifyModal" @success="search"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import ModifyCompanyModal from "./modifyCompanyModal";

export default {
  name: "exposureList",
  components: {ModifyCompanyModal},
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "company/listByAdmin",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 100,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "公司名称",
          width: 220,
          align: "center",
          dataIndex: "company",
          // scopedSlots: { customRender: "company" },
        },
        {
          title: "公司全称",
          width: 220,
          align: "center",
          dataIndex: "standardName",
          // scopedSlots: { customRender: "company" },
        },
        {
          title: "统一社会信用代码",
          width: 170,
          align: "center",
          dataIndex: "bizCreditNo",
        },
        {
          title: "营业执照",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "bizCreditImg"},
        },
        {
          title: "区域",
          width: 120,
          align: "center",
          customRender: (text, row, index) => {
            return (row.province || '') + (row.city || '')
          }
        },
        {
          title: "地址",
          align: "center",
          width: 250,
          customRender: (text, row, index) => {
            let str =
                (row.province || "") +
                (row.city || "") +
                (row.area || "") +
                (row.town || "") +
                (row.address || "");
            return <small>{str}</small>;
          },
        },
        // {
        //   title: "欠款金额",
        //   align: "center",
        //   width: 100,
        //   scopedSlots: { customRender: "amount" },
        // },
        // {
        //   title: "最早欠款日期",
        //   width: 105,
        //   align: "center",
        //   dataIndex: "firstDebtDate",
        // },
        // {
        //   title: "最晚欠款日期",
        //   width: 105,
        //   align: "center",
        //   dataIndex: "lastDebtDate",
        // },
        {
          title: "法定代表人",
          width: 120,
          align: "center",
          dataIndex: "legalPerson",
        },
        // {
        //   title: "欠款人电话",
        //   width: 100,
        //   align: "center",
        //   dataIndex: "mobile",
        // },
        {
          title: "关联帖子数",
          align: "center",
          width: 100,
          dataIndex: "complainTime",
        },
        {
          title: "经营范围",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "bizScope",
        },
        {
          title: "备注",
          width: 150,
          align: "center",
          dataIndex: "remark",
        },
        {
          title: "创建日期",
          align: "center",
          width: 120,
          dataIndex: "create_time",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.fetch();
  },
  methods: {
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'edit');
    },
    deleteOne(record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除公司【" + record.company + "】吗？！",
        centered: true,
        onOk() {
          that.$post("company/delete", {id: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  }
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
